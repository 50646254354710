import firebase from 'firebase/app'
import "firebase/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBIdkk33PaaX9qzHolzIMrIqftQY3OM9V0",
  authDomain: "iboatnyc-41ea7.firebaseapp.com",
  projectId: "iboatnyc-41ea7",
  storageBucket: "iboatnyc-41ea7.appspot.com",
  messagingSenderId: "901280626100",
  appId: "1:901280626100:web:b54eb048f79814e4ebaee1"
})



export const db = app.firestore()
export default app;