import '../index.css';
import React from 'react'
import Index from './Index';
import Category from './Category';
import Login from './Login';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Route exact path='/' component={Index}></Route>
        {/* <Route path='/c/:category' component={Category}></Route> */}
        {/* <Route path='/login' component={Login}></Route> */}
      </Router>
    </>
  );
}

export default App;
