/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import MetaTags from 'react-meta-tags';
import ReactPixel from 'react-facebook-pixel'

export default function Index() {
    const svgColor = '#DA9291'
    const topGradient = '#00000'
    const bottomGradient = "#BB6367"
    const groupId = '61d5a781f46e2a0033a6494b'
    const pixelId = '531907628832954'

    const socialShareLink = 'https://postreact.s3.us-east-2.amazonaws.com/rose-gold/Rose_Gradient.png'
    const metaDescription = ''
    const metaTitle = 'Rose Gold'
    const website = 'https://www.rosegoldevents.vip'

    const [events, setEvents] = useState({});
    const [previousEvents, setPreviousEvents] = useState({});
    const [isEvents, setIsEvents] = useState(false);
    const [nightlifeCount, setNCount] = useState(0);
    const [concertCount, setCCount] = useState(0);
    const [holidayCount, setHCount] = useState(0);


    ReactPixel.init(pixelId)
    ReactPixel.pageView()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetch(`/poshAPI/${groupId}`)
            .then(async (res) => await res.json())
            .then(async (eventsAPI) => {
                if (eventsAPI.events) {
                    setEvents(eventsAPI.events);
                    setPreviousEvents(eventsAPI.previousEvents)
                    setNCount(eventsAPI.nightlifeCount)
                    setCCount(eventsAPI.concertCount)
                    setHCount(eventsAPI.holidayCount)
                    setIsEvents(true);
                }
            })
        
        }, [isEvents]);
    return (
        <>
            <MetaTags>
                <title>Rose Gold</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/assets/img/favicon.png" />
                <link href="/css/index.css" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={socialShareLink} />
                <meta property="og:url" content={website} />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <meta property="twitter:image" content={socialShareLink}/>
                <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'></link>
                <meta name="twitter:card" content="summary_large_image"></meta>
            </MetaTags>
            <svg viewBox="0 0 100 100" preserveAspectRatio="none" id="svg-A">
                <defs>
                    <linearGradient id="linearA" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="30%" stop-color={topGradient}></stop>
                        <stop offset="100%" stop-color={bottomGradient}></stop>
                    </linearGradient>
                </defs>
                <path d="M 0 25 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: svgColor }}></path>
                <path d="M 0 30 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: 'url(#linearA)' }}></path>
            </svg>
            <div style={{ position: 'relative', width: "100%", minHeight: "900px", display: 'flex', justifyContent: 'center' }}>
                <div className="body-inner-b">
                    <Header></Header>
                    {(isEvents && events && events.length > 0) &&
                        <>
                            <h2 style={{color: 'white'}}>NEW YEARS EVE</h2>
                            {events && events.length > 0 && 
                                <div className="event-category-cont"> 
                                        <div className="desctop-scroll">
                                            {events.map(event => (
                                            event.status !== 'deleted' && 
                                                (<>
                                                    <a href={'https://posh.vip/e/' + event.url}>
                                                        <div className='ecc-e'>
                                                            <img src={event.flyer} className="ecc-e__flyer" />
                                                            <div className='ecc-e__filter'></div>
                                                            <div className='text-wrapper'>
                                                                <h1 className='event-date-small'>{event.displayDay}</h1>
                                                                <h1 className='event-date-large'>{event.dayOfMonth}</h1>
                                                            </div>
                                                            
                                                        </div>
                                                    </a>
                                                </>)
                                            ))}
                                        </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
